












































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
// import SignService from '@/service/sign';
import SignService from '@/service/sign-v2';
import { UserStoreModule } from '@/store/modules/user';
import { UserModel } from '@/model/user-model';
import CommonService from '@/service/common';

@Component
export default class LoginComponent extends BaseComponent {
    form = {
        username: '',
        password: '',
        remember: true
    };
    rules = {
        username: [
            { required: true, message: '请输入手机号', trigger: 'change' }
            // { pattern: MOBILE_REGEXP, message: '请输入正确手机号', trigger: 'change' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }]
    };

    mounted() {
        window.addEventListener('keydown', this.keyDown);
        // UserStoreModule.SET_REDIRECT_PATH(_.get(this.$route, 'query.redirectPath'));
    }

    destroyed() {
        window.removeEventListener('keydown', this.keyDown);
    }

    get ProjectName(): string {
        return UserStoreModule.CorpName;
    }

    keyDown(e) {
        if (e.keyCode === 13) {
            this.loginClick();
        }
    }

    async loginClick() {
        let validateResult = false;
        try {
            validateResult = await (this.$refs.ruleForm as any).validate();
        } catch (e) {
            return false;
        }
        if (validateResult) {
            if (this.form.remember) {
                // this.setCookie(this.form.username, this.form.password, 7);
            } else {
                this.clearCookie();
            }
            return SignService.login(this.form.username, this.form.password)
                .then(async(res) => {
                    UserStoreModule.SET_USERINFO(new UserModel().toModel(res));
                    UserStoreModule.SET_REDIRECT_PATH();
                    // UserStoreModule.SET_CURRENT_PROJECT(null);
                    await CommonService.getUserInfo();
                    if (!_.get(UserStoreModule.UserInfo, 'IsSuperAdmin')) {
                        // 初始化项目信息
                        // 默认选中第一个项目
                        await CommonService.initProject(600000);
                    }
                    this.$router.push(UserStoreModule.RedirectPath);
                })
                .catch(err => {
                    throw err;
                });
        }
    }

    setCookie(cname:string, cpwd:string, exdays:number) {
        var exdate = new Date();
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays);

        window.document.cookie = 'userName = ' + cname + ';path=/;expires=' + exdate.toUTCString();
        window.document.cookie = 'userPwd = ' + cpwd + ';path=/;expires=' + exdate.toUTCString();
    }

    getCookie() {
        if (window.document.cookie.length > 0) {
            var arr = document.cookie.split('; ');
            for (var i = 0; i < arr.length; i++) {
                var arr2 = arr[i].split('=');
                if (arr2[0] === 'userName') {
                    this.form.username = arr2[1];
                } else if (arr2[0] === 'userPwd') {
                    this.form.password = arr2[1];
                    this.form.remember = true;
                }
            }
        }
    }

    clearCookie() {
        this.setCookie('', '', -1);
    }
}
